<template>
  <div class="card card-padding create-request-idea">
    <div class="d-flex justify-content-between align-items-center">
      <router-link class="backBtn" :to="'/business/feedback/' + (createType === 'Request' ? 'requests' : 'ideas')">
        <img src="/media/buying/icons/general/arr074.svg" alt="">
        {{ $t("Cancel") }}
      </router-link>
      <el-radio-group
        class="radioTab"
        v-model="createType"
        @change="handleTypeChange"
      >
        <el-radio-button :label="'request'" name="Request">{{$t('Request')}}</el-radio-button>
        <el-radio-button :label="'idea'" name="Ideas">{{ $t('Idea') }}</el-radio-button>
      </el-radio-group>
    </div>

    <CreateRequestOrIdeaForm
      :type="createType"
      :buttonIndicator="buttonIndicator"
      @handle-save="createRequestOrIdea"
    />
  </div>
</template>

<script>
import store from "@/store";
import { redirectToRouteWithName } from "@/buying-teams/helpers/UrlHelper";
import NotificationService from "@/buying-teams/services/NotificationService";
import CreateRequestOrIdeaForm from "@/buying-teams/shared-components/request-idea/CreateRequestOrIdeaForm";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import {diagLog} from "@/core/helpers/GlobalHelper";

export default {
  name: "CreateRequestOrIdea",
  components: {
    CreateRequestOrIdeaForm,
  },

  props: [],

  data() {
    return {
      buttonIndicator: 'off',
      createType: this.$route.meta.createType
    };
  },

  methods: {
    async createRequestOrIdea(data) {
      this.createType === 'request' ? await this.createRequest(data) : await this.createIdea(data)
    },
    async createIdea(data) {
      this.buttonIndicator = 'on'
      await store.dispatch("createIdea", data)
        .then(res => {
          redirectToRouteWithName('business-ideas');
        })
        .catch(error => {
          console.log("error", error);
          NotificationService.swalError(error);
        }).finally(() => {
          this.buttonIndicator = 'off'
        })
    },
    async createRequest(data) {
      this.buttonIndicator = 'on'
      await store.dispatch("createRequest", data)
        .then(res => {
          redirectToRouteWithName('business-requests');
          diagLog('res --- ', res);
        })
        .catch(error => {
          console.log("error", error);
          NotificationService.swalError(error);
        }).finally(() =>{
          this.buttonIndicator = 'off'
        })
    },
    detectPageTitle() {
      if (this.createType === 'request') {
        setCurrentPageBreadcrumbs({
          title: this.$t('New Request'),
          subTitle: this.$t('Requests for your Banks (action, change, other)')
        })
      } else {
        setCurrentPageBreadcrumbs({
          title: this.$t('New Idea'),
          subTitle: this.$t('Ideas for your Banks (improvements, developments, other)')
        })
      }
    },
    handleTypeChange() {
      this.$router.push(`/business/feedback/${this.createType}/create`)
    }
  },
  mounted() {
    this.detectPageTitle();
  },
  watch: {
    '$route': {
      handler() {
        this.detectPageTitle();
      }
    }
  }
};
</script>

<style lang="scss">
.demo-filterForm {
  padding-right: 10%;
  border-right: 1px solid #eee;
  position: relative;

  .closeFilter {
    border: none;
    margin-left: auto;
    display: block;
    position: absolute;
    right: 13px;
    top: -35px;
    cursor: pointer;
  }

  .el-date-editor {
    background: transparent;
    padding: 0;
    width: 100%;

    .el-range-input {
      background: #FFFFFF;
      border: 1px solid #E3E3E3;
      border-radius: 5px;
      width: 100%;
    }

    .el-icon {
      display: none;
    }
  }

  .el-select {
    width: 100%;

    .el-input__inner {
      background: #FFFFFF;
      border: 1px solid #E3E3E3;
      border-radius: 5px;
      width: 100%;
    }
  }

  .custom-check {
    .el-checkbox-button__inner {
      border: none;
      border-left: none !important;
      max-width: 100px;
      display: flex;
      flex-direction: column;

      .circle-image {
        width: 34px;
        height: 34px;
        border-radius: 50%;
        background: #15523c;
        border: 9px solid #F2F2F2;
        box-sizing: content-box;
        display: block;
      }

      p {
        font-size: 12px;
        white-space: break-spaces;
        text-align: center;
        line-height: 15px;
      }
    }

    &.is-checked {
      --el-checkbox-button-checked-bg-color: transparent;
      --el-checkbox-button-checked-text-color: #435BF4;
      --el-checkbox-button-checked-border-color: #fff;

      p {
        color: #435BF4;
      }

      .el-checkbox-button__inner {
        box-shadow: none;
      }

      .circle-image {
        outline: 2px solid #435BF4;
      }
    }
  }

  .priority {
    .el-checkbox-button__inner {
      background: #F1F1F1;
      border-radius: 40px !important;
      color: #5B5C61;
      margin: 0 12px;
    }

    .is-checked {
      .el-checkbox-button__inner {
        background: #435BF4;
        color: #FFFFFF;
      }
    }
  }

  .switch {
    display: flex;
    align-items: center;

    .el-form-item__content {
      text-align: right;
    }
  }

  .el-form-item__label {
    font-weight: 500;
    font-size: 17px;
    line-height: 21px;
    color: #4F4F4F;
  }

}

.filter-btn {
  border: 1px solid #CED4F8;
  background: #FFFFFF;
  box-shadow: 0 9px 29px rgba(0, 0, 0, 0.06);
  color: #435BF4;
  padding: 15px 20%;
  margin: 0 auto;
  display: block;
}

@media screen and (max-width: 1500px) {
  .create-request-idea {
    .radioTab .el-radio-button__inner {
      padding: 10px 25px;
    }
  }
}
</style>

